import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

export default function Comp(props) {
  let InputProps = props.InputProps || {};



  if (props.icona) {
    InputProps.startAdornment = (
      <InputAdornment position="start">{props.icona}</InputAdornment>
    );
  }

  return <TextField {...props} fullWidth size="small" 
  InputProps={InputProps} 
  InputLabelProps={{ shrink: true }}
  />;
}
