import Button from "@mui/material/Button";
// import { Link } from "react-router-dom";
// import Testo from "../elementi/Testo";
// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
import PopupElimina from "../elementi/PopupElimina";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import DownloadIcon from "@mui/icons-material/Download";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { MediaUpload } from "./MediaUpload";
import {
  convertiData,
  // controllaLogin,
  monFetch,
  monMedia,
  isAdminGlobale,
  // isIntervistatore,
} from "../inc/inc";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
// import AutocompleteMon from "../elementi/AutocompleteMon";
import DeleteIcon from "@mui/icons-material/Delete";
// import MenuBookIcon from "@mui/icons-material/MenuBook";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useLocation, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
// import CheckBoxMon from "../elementi/CheckBoxMon";

const Comp = (props: any) => {
  //const ruoloLogin = controllaLogin();
  let params = useParams();
  // let location = useLocation();

  let id = params.id;
  if (!id) {
    id = Cookies.get("id");
  }
  //se sono in pagina di modifica utente loggato

  const [files, setFiles] = useState([]);
  const [popupElimina, setPopupElimina] = useState(false);
  const [daEliminare, setDaEliminare] = useState<any>(null);

  async function queryFiles(id: any) {
    const res = await monFetch("/userFilesList", {
      id: id,
    });
    // console.log(res);
    setFiles(res.res);
  }

  async function downloadFile(id: string, nomeFile: string) {
    let res;

    res = await monMedia("/downloadFile", { media_id: id });

    var url = window.URL.createObjectURL(res);
    var a = document.createElement("a");
    a.href = url;
    a.download = nomeFile;
    document.body.appendChild(a);
    // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove(); //afterwards we remove the element again
  }

  async function deleteFile(media_id: string) {
    let res = await monMedia("/deleteFile", { media_id: media_id });

    queryFiles(id);
    setPopupElimina(false);
  }

  async function vediFile(id: string, nomeFile: string) {
    window.open(
      process.env.REACT_APP_BACKEND +
        "/getFile/" +
        id +
        "/" +
        Cookies.get("token") +
        "/" +
        nomeFile
    );
  }

  useEffect(() => {
    props.setTestoTitolo("Documenti Utente");

    if (id) {
      queryFiles(id);
    }
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          {isAdminGlobale() && (
            <>
              <h4>Carica Files</h4>
              <MediaUpload
                userId={id}
                fnReload={() => {
                  queryFiles(id);
                }}
              />
            </>
          )}
          <Table style={{ marginTop: 0 }}>
            <TableHead>
              <TableRow>
                {/* <TableCell>Azienda</TableCell> */}
                <TableCell>Nome File</TableCell>
                <TableCell>Utente</TableCell>
                <TableCell>Dimensione</TableCell>
                <TableCell>Data aggiunta</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map((el: any) => {
                let nomeUtente = el.first_name + " " + el.last_name;
                if (el.company_name) {
                  nomeUtente += " | " + el.company_name + "";
                }
                return (
                  <TableRow key={el.id}>
                    <TableCell>
                      <b>{el.nome_file}</b>
                    </TableCell>
                    <TableCell>{nomeUtente}</TableCell>
                    <TableCell>{el.size} MB</TableCell>
                    <TableCell>{convertiData(el.date_insert)}</TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "right",
                          gap: 20,
                        }}
                      >
                        <RemoveRedEyeIcon
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            vediFile(el.id, el.nome_file);
                          }}
                        />
                        <DownloadIcon
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            downloadFile(el.id, el.nome_file);
                          }}
                        />
                        {isAdminGlobale() && (
                          <DeleteIcon
                            color="error"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setPopupElimina(true);
                              setDaEliminare(el.id);
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
        <Grid item sm={12} xs={12} style={{ textAlign: "right" }}>
          <div className="flexSpaceBetween">
            <Button
              className="buttonIndietro"
              href={ "/"}
              variant="contained"
            >
              Indietro
            </Button>
          </div>
        </Grid>
      </Grid>

      <PopupElimina
        open={popupElimina}
        fnAnnulla={() => setPopupElimina(false)}
        fnSuccess={() => deleteFile(daEliminare)}
      />
    </>
  );
};

export default Comp;
