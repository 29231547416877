import TextField from "@mui/material/TextField";
import { monFetch, monForm } from "../inc/inc";
import Autocomplete from "@mui/material/Autocomplete";
import { useState, useEffect } from "react";

import { FileUploader } from "react-drag-drop-files";

const MediaUpload = (p: any) => {
  // console.log("Media Upload P", p, tree);
  const [nomiFile, setNomiFile] = useState([]);

  const filesCaricati: any = [];

  async function caricaFile(file: any, indice: number, nomi: any) {
    var formData = new FormData();
    let userId = p.userId;

    // const d = new Date();
    // let nomeFile = d.getTime() + userId;
    let nome = file.name;

    formData.append("file", file);
    formData.append("user_id", userId);
    formData.append("file_name", nome);

    const res = await monForm("/userFilesUpload", formData);
    if (res.fileCaricato) {
      filesCaricati.push(indice);

      let nomi2: any = [...nomi];
      //trovo quelli caricati
      filesCaricati.forEach((el: any) => {
        nomi2[el] = "File Caricato!";
      });
      setNomiFile(nomi2);

      p.fnReload();
    }
  }

  useEffect(() => {}, []);

  return (
    <div className="boxMedia boxUpload">
      <FileUploader
        label="Carica file"
        multiple
        name="fileUpload"
        handleChange={(files: any) => {
          let nomi: any = [];
          // console.log(files);
          Array.from(files).forEach((file: any, index: number) => {
            nomi.push(file?.name);
          });
          setNomiFile(nomi);
          Array.from(files).forEach((file: any, index: number) => {
            caricaFile(file, index, nomi);
          });
        }}
      />
      <div style={{ marginTop: 15, display: "flex", gap: 20 }}>
        {nomiFile.map((el: any, index: number) => {
          return <div key={index}>{el}</div>;
        })}
      </div>
    </div>
  );
};

export { MediaUpload };
