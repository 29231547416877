import * as React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountCircle from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import { isAdminAzienda, isAdminGlobale } from "../../inc/inc";

import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import GroupIcon from "@mui/icons-material/Group";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import HistoryIcon from "@mui/icons-material/History";

const ElemLista = (props) => {
  return (
    <ListItem disablePadding>
      <ListItemButton href={props.href}>
        <ListItemIcon>{props.icona}</ListItemIcon>
        <ListItemText primary={props.testo} />
      </ListItemButton>
    </ListItem>
  );
};

const Comp = (props) => {
  return (
    <Drawer
      className="menuLaterale"
      anchor="left"
      open={props.menuAperto}
      onClose={() => props.setMenuAperto(!props.menuAperto)}
    >
      <List style={{ minWidth: 250 }}>
        <ElemLista
          href={ "/"}
          testo="Home"
          icona={<HomeIcon />}
        />

        {isAdminGlobale() && (
          <ElemLista
            href={ "/utenti"}
            testo="Utenti"
            icona={<AccountCircle />}
          />
        )}
      </List>
    </Drawer>
  );
};

export default Comp;
