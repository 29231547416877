import * as React from "react";
import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Login from "./comp/Login.js";
import RecuperaPassword from "./comp/RecuperaPassword";
import ReimpostaPassword from "./comp/ReimpostaPassword";
import Abilita2fa from "./comp/Abilita2fa";

import UtentiLista from "./comp/UtentiLista";
import UtentiModifica from "./comp/UtentiModifica";
import UtentiFiles from "./comp/UtentiFiles";

import Layout from "./comp/layout/Layout";

import { isAdminGlobale } from "./inc/inc.js";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#ccc",
      },
    },
  });

  const [testoTitolo, setTestoTitolo] = useState("");

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />

        <Routes>
          <Route path={"/login"} element={<Login />} />

          <Route path={"/recupera-password"} element={<RecuperaPassword />} />
          <Route path={"/reimposta-password"} element={<ReimpostaPassword />} />

          <Route path={"/"} element={<Layout testoTitolo={testoTitolo} />}>
            <Route
              path="/"
              element={
                isAdminGlobale() ? (
                  ////admin home
                  <UtentiLista
                    testoTitolo={testoTitolo}
                    setTestoTitolo={setTestoTitolo}
                  />
                ) : (
                  //utente home
                  <UtentiFiles
                  nuovo={false}
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
                )
              }
            />
            <Route path="abilita2fa" element={<Abilita2fa />} />
            <Route
              path={"/utenti"}
              element={
                <UtentiLista
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />
            <Route
              path={"/utente-nuovo"}
              element={
                <UtentiModifica
                  nuovo={true}
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />
            <Route
              path={"/utente/:id"}
              element={
                <UtentiModifica
                  nuovo={false}
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />

            <Route
              path={"/utente-modifica"}
              element={
                <UtentiModifica
                  nuovo={false}
                  utenteLoggato={true}
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />

            <Route
              path={"/utente/files/:id"}
              element={
                <UtentiFiles
                  nuovo={false}
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
