import Cookies from "js-cookie";

function pallino(props) {
  let colore;
  if (props.attivo === 1) {
    colore = "verde";
  } else {
    colore = "rosso";
  }

  return <div className={"pallino " + colore}></div>;
}

function convertiData(input) {
  if (!input) {
    return;
  }
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(input);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
}

function convertiDataHM(input) {
  if (!input) {
    return;
  }
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(input);
  let data = [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(
    "/"
  );
  let oreMinuti = pad(d.getHours()) + ":" + pad(d.getMinutes());
  return data + " " + oreMinuti;
}

const controllaLogin = () => {
  if (!Cookies.get("token")) {
    window.location.href =  "/login";
  }
};

const isAdminGlobale = () => {
  if (Cookies.get("adminLevel") === "3") {
    return true;
  } else {
    return false;
  }
};

const isIntervistatore = () => {
  if (Cookies.get("adminLevel") === "2") {
    return true;
  } else {
    return false;
  }
};

// const isUtente = () => {
//   if (Cookies.get("adminLevel") === "1") {
//     return true;
//   } else {
//     return false;
//   }
// };

const monFetch = async (percorso, data) => {
  const response = await fetch(process.env.REACT_APP_BACKEND + percorso, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      monToken: Cookies.get("token"),
      monId: Cookies.get("id"),
      // monComId: Cookies.get("companyId"),
    },
    body: JSON.stringify(data),
  });
  let res = await response.json();
  if (res.monCheck === false) {
    Cookies.remove("token");
    Cookies.remove("id");
    Cookies.remove("companyId");
    Cookies.remove("adminLevel");
    Cookies.remove("roles");
    window.location.href =  "/login";
  }
  //console.log(res);
  return res;
};

const monMedia = async (percorso, data) => {
  const response = await fetch(process.env.REACT_APP_BACKEND + percorso, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      monToken: Cookies.get("token"),
      monId: Cookies.get("id"),
      monComId: Cookies.get("companyId"),
    },
    body: JSON.stringify(data),
  });
  //console.log(response.headers.get('Content-Disposition'))
  let res = await response.blob();
  //console.log(res);
  return res;
};

const monForm = async (percorso, data) => {
  const response = await fetch(process.env.REACT_APP_BACKEND + percorso, {
    method: "POST",
    headers: {
      monToken: Cookies.get("token"),
      monId: Cookies.get("id"),
      monComId: Cookies.get("companyId"),
    },
    body: data,
  });
  let res = await response.json();
  //console.log(res);
  return res;
};

const selezionaElemento = (selettore, id) => {
  document.querySelectorAll(selettore).forEach((el) => {
    el.classList.remove("elSelezionato");
    if (el.getAttribute("data-id") === id) {
      el.classList.add("elSelezionato");
    }
  });
};

const checkRuoloUtente = (role) => {
  //controlla se nell'array di id di ruoli è presente role
  //array di ruoli
  if (Cookies.get("roles")) {
    let ruoli = JSON.parse(Cookies.get("roles"));
    //console.log(ruoli);
    if (ruoli.includes(role)) {
      return true;
    }
  }
  return false;
};

/**
 * Manda una notifica al browser
 * @param string t Titolo della notifica
 * @param string b Corpo della notifica
 */
const notifyMe = (t, b) => {
  if ("Notification" in window) {
    console.log("NOTIFICA TRIGGER", t, b);
    var title = t;
    var options = {
      body: b,
      icon: "/img/logo.png",
    };
    if (Notification.permission === "granted") {
      var notification = new Notification(title, options);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission(function (permission) {
        if (permission === "granted") {
          var notification = new Notification(title, options);
        }
      });
    }
  }
};

// torna tutti i dati delle cose da fare
const notificationPolling = async function () {
  const res = await monFetch("/polling", {});
  return res;
};

const apriAccordion = (target, padre, figlio, direzione = null) => {
  console.log(target.closest(".boxAccordion"));
  let isAperto = target.closest(".boxAccordion").classList.contains("aperto");

  //cerco la classe univoca
  let indice;
  target.closest(".boxAccordion").classList.forEach((el) => {
    if (el.startsWith("bsez")) {
      indice = el;
      return;
    }
  });

  let selettorePadre = "." + padre + "." + indice;
  let selettoreFiglio = selettorePadre + " ." + figlio;
  let altezzaDasettare = 0;

  let el = document.querySelector(selettorePadre);

  let fnDopoAnimazione = function () {
    // console.log("fneee", el);
    document.querySelector(selettorePadre).style.height = "auto";
    el.removeEventListener("transitionend", fnDopoAnimazione);
  };

  if ((isAperto == false && direzione != "chiudi") || direzione == "apri") {
    target.closest(".boxAccordion").classList.add("aperto");
    el.addEventListener("transitionend", fnDopoAnimazione);

    el.style.height = el.clientHeight + "px";

    document.querySelector(selettoreFiglio).style.display = "block";
    altezzaDasettare += el.clientHeight;
    altezzaDasettare += document.querySelector(selettoreFiglio).clientHeight;

    // console.log(altezzaDasettare);
    el.style.height = altezzaDasettare + "px";
    // setTimeout(()=>{

    // }, 600)
  } else {
    target.closest(".boxAccordion").classList.remove("aperto");
    el.style.height = el.clientHeight + "px";

    el.style.height =
      document.querySelector(selettorePadre + " > *:first-child").clientHeight +
      14 +
      "px";
  }
};

const classeColorePerc = (valore, skipGrav0 = true) => {

  let classe = "";
  if (valore == 50 && !skipGrav0) {
    classe = "grav0";
  } else if (valore <= 70) {
    classe = "grav1";
  } else if (valore <= 99) {
    classe = "grav3";
  } else {
    classe = "grav7";
  }
  return classe;
};


export {
  pallino,
  convertiData,
  convertiDataHM,
  controllaLogin,
  monFetch,
  monForm,
  monMedia,
  selezionaElemento,
  checkRuoloUtente,
  notifyMe,
  notificationPolling,
  isAdminGlobale,
  isIntervistatore,
  apriAccordion,
  classeColorePerc,
};
