// import LibriAggiorna from "./LibriAggiorna";
// import PrenotaLibro from "./PrenotaLibro";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Testo from "../elementi/Testo";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import {
  convertiData,
  controllaLogin,
  monFetch,
  isAdminGlobale,
} from "../inc/inc";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import AutocompleteMon from "../elementi/AutocompleteMon";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useLocation, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import CheckBoxMon from "../elementi/CheckBoxMon";

const Comp = (props: any) => {
  //const ruoloLogin = controllaLogin();
  let params = useParams();
  let location = useLocation();

  let id = params.id;
  //se sono in pagina di modifica utente loggato
  if (props.utenteLoggato) {
    id = Cookies.get("id");
  }

  // const [clienti, setClienti] = useState([]);

  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [email, setEmail] = useState("");
  const [attivo, setAttivo] = useState(false);
  const [livelloAdmin, setLivelloAdmin] = useState(0);
  const [azienda, setAzienda] = useState("");
  const [telefono, setTelefono] = useState("");
  const [prefisso, setPrefisso] = useState("+39");
  const [comune, setComune] = useState("");
  const [provincia, setProvincia] = useState("");
  const [cap, setCap] = useState("");
  const [indirizzo, setIndirizzo] = useState("");
  const [piva, setPiva] = useState("");
  const [cf, setCf] = useState("");

  const [c2fa, setC2fa] = useState(false);

  const [optionsLivello, setOptionsLivello] = useState<any>([]);

  async function queryUtente(id: any) {
    const res = await monFetch("/userGet", {
      id: id,
    });
    // console.log(res);
    const user = res.user;
    setNome(user.first_name || "");
    setCognome(user.last_name || "");
    setEmail(user.email || "");
    setAttivo(user.enabled ? true : false);
    setLivelloAdmin(user.admin_level);
    setAzienda(user.company_name || "");
    setC2fa(user.enabled2fa ? true : false);
    setTelefono(user.phone || "");
    setPrefisso(user.phonePrefix || "");
    setComune(user.city || "");
    setProvincia(user.prov || "");
    setCap(user.cap || "");
    setIndirizzo(user.address || "");
    setPiva(user.piva || "");
    setCf(user.cf || "");
  }

  // async function queryRuoli() {
  //   const res = await monFetch("/roleList", {});
  //   // console.log(res);
  //   setRuoli(res.role);
  // }

  async function queryLivelliAdmin() {
    const res = await monFetch("/adminLevelList", {});
    // console.log(res);
    const livelliAdmin: any = [];
    res.level.forEach((el: any) => {
      livelliAdmin.push({ label: el.level, value: el.id });
    });
    // console.log(livelliAdmin);
    setOptionsLivello(livelliAdmin);
  }

  // async function queryAziende() {
  //   const res = await monFetch("/companyList", {});
  //   // console.log(res);
  //   let aziende = [];
  //   res.company.forEach((el) => {
  //     aziende.push({ label: el.name, id: el.id });
  //   });
  //   // console.log(aziende);
  //   setAziende(aziende);
  // }

  async function salvaUtente(e: any, id: any) {
    e.preventDefault();

    const data = {
      id: id,
      email: email,
      first_name: nome,
      last_name: cognome,
      active: attivo ? 1 : 0,

      admin_level: livelloAdmin,
      company_name: azienda,
      enabled2fa: c2fa,
      telefono,
      prefisso,
      comune,
      provincia,
      cap,
      indirizzo,
      piva,
      cf,
    };

    const res = await monFetch("/userSet", data);
    // console.log(res);
    // return
    if (res.success) {
      if (res.id) {
        window.location.href =  "/utenti";
      } else {
        window.location.href =  "/";
      }
    }
  }

  useEffect(() => {
    props.setTestoTitolo(props.nuovo ? "Aggiungi Utente" : "Modifica Utente");

    queryLivelliAdmin();

    if (id) {
      queryUtente(id);
    }
  }, []);

  return (
    <form style={{ paddingTop: 15 }} onSubmit={(e) => salvaUtente(e, id)}>
      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          <Testo
            label="Email"
            required
            type="email"
            value={email}
            onChange={(e: any) => {
              setEmail(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Testo
            label="Nome"
            required
            value={nome}
            onChange={(e: any) => {
              setNome(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Testo
            label="Cognome"
            required
            value={cognome}
            onChange={(e: any) => {
              setCognome(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Testo
            label="Azienda"
            value={azienda}
            onChange={(e: any) => {
              setAzienda(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={1} xs={12}>
          <Testo
            label="Prefisso"
            required
            value={prefisso}
            onChange={(e: any) => {
              setPrefisso(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <Testo
            label="Telefono"
            required
            value={telefono}
            onChange={(e: any) => {
              setTelefono(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Testo
            label="Comune"
            value={comune}
            onChange={(e: any) => {
              setComune(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Testo
            label="Provincia"
            value={provincia}
            onChange={(e: any) => {
              setProvincia(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Testo
            label="CAP"
            value={cap}
            onChange={(e: any) => {
              setCap(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Testo
            label="Indirizzo"
            value={indirizzo}
            onChange={(e: any) => {
              setIndirizzo(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Testo
            label="P.Iva"
            value={piva}
            onChange={(e: any) => {
              setPiva(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Testo
            label="CF"
            value={cf}
            onChange={(e: any) => {
              setCf(e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <br />
      <Grid container spacing={2}>
        {isAdminGlobale() && (
          <Grid item sm={4} xs={12}>
            <h4>Livello Admin</h4>
            <AutocompleteMon
              value={
                optionsLivello.find((op: any) => op.value == livelloAdmin) ||
                null
              }
              onChange={(e: any, value: any) => {
                console.log("change autoc", value);
                if (!value) {
                  return;
                }
                setLivelloAdmin(value.value);
              }}
              options={optionsLivello}
              label="Livello"
              inputRequired={true}
            />
          </Grid>
        )}

        <Grid item sm={4} xs={12}>
          <h4>Autenticazione a 2 fattori: {c2fa ? "Attiva" : "Disattivata"}</h4>
          {c2fa == true ? (
            <></>
          ) : // <Button
          //   className="buttonError"
          //   variant="contained"
          //   onClick={(e) => disattiva2fa(e, id)}
          // >
          //   Disattiva 2FA
          // </Button>
          //mostro solo se sono nella pagina dell'utente loggato
          props.utenteLoggato ? (
            <Button
              className="buttonSuccess"
              href={ "/abilita2fa"}
              variant="contained"
            >
              Attiva 2FA
            </Button>
          ) : (
            "L'utente deve attivare la 2FA."
          )}

          <br />
          <br />
        </Grid>
        {isAdminGlobale() && (
          <Grid item sm={12} xs={12}>
            <CheckBoxMon
              className="paddingSez"
              checked={attivo}
              onChange={(e: any) => setAttivo(e.target.checked)}
              label={"Attivo"}
            />
          </Grid>
        )}

        <Grid item sm={12} xs={12} style={{ textAlign: "right" }}>
          <div className="flexSpaceBetween">
            <Button
              className="buttonIndietro"
              href={ "/"}
              variant="contained"
            >
              Indietro
            </Button>
            <Button className="buttonSalva" type="submit" variant="contained">
              Salva
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default Comp;
