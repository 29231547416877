import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import LibriAggiorna from "./LibriAggiorna";
// import PrenotaLibro from "./PrenotaLibro";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Pallino, convertiData, controllaLogin, monFetch } from "../inc/inc";
import Cookies from "js-cookie";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import FolderIcon from "@mui/icons-material/Folder";
import PopupElimina from "../elementi/PopupElimina";
import PopupMail from "../elementi/PopupMail";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import Testo from "../elementi/Testo";

import Pagination from "@mui/material/Pagination";

const Comp = (props) => {
  //const ruoloLogin = controllaLogin();

  let location = useLocation();

  const [utenti, setUtenti] = useState([]);
  const [cerca, setCerca] = useState("");

  const [popupElimina, setPopupElimina] = useState(false);
  const [utenteDaEliminare, setUtenteDaEliminare] = useState(null);

  const [popupEmail, setPopupEmail] = useState(false);
  const [utenteEmail, setUtenteEmail] = useState(null);

  const [nPagine, setNPagine] = useState(1);
  const [pagina, setPagina] = useState(1);

  async function query(pagina = 1) {
    console.log("QUERY");
    const res = await monFetch("/userList", {
      cerca: cerca,
      pagina,
    });
    // console.log(res);
    setNPagine(res.pagine);
    setUtenti(res.users);
  }

  async function eliminaUtente(id) {
    const res = await monFetch("/userDelete", { id });
    // console.log(res);
    setPopupElimina(false);
    query();
  }

  async function inviaEmail(email) {
    const res = await monFetch("/userEmail", { email });
    // console.log(res);
    if (res.success) {
      setPopupEmail(false);
      query();
    }
  }

  useEffect(() => {
    props.setTestoTitolo(
      <>
        <Button
          variant="contained"
          href={ "/utente-nuovo"}
          className="buttonSalva"
          style={{ marginRight: 20 }}
        >
          Nuovo{" "}
        </Button>
        Utenti
      </>
    );
  }, []);

  useEffect(() => {
    query(1);
  }, [cerca]);

  return (
    <>
      <div style={{ paddingTop: 10 }}>
        <Testo
          label="Cerca utente o azienda"
          value={cerca}
          onChange={(e) => {
            setCerca(e.target.value);
          }}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            {/* <TableCell>Azienda</TableCell> */}
            <TableCell>Nome</TableCell>
            <TableCell>Cognome</TableCell>
            <TableCell>Azienda</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Livello</TableCell>
            <TableCell>Attivo</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {utenti.map((utente) => {
            let isUtenteLoggato = false;
            if (Cookies.get("id") == utente.id) {
              isUtenteLoggato = true;
            }
            return (
              <TableRow
                key={utente.id}
                className="rigaSelezionabile"
                style={{ opacity: isUtenteLoggato ? 0.5 : 1 }}
                onClick={(e) => {
                  if (isUtenteLoggato) {
                    return;
                  }
                  window.location.href =
                     "/utente/" + utente.id;
                }}
              >
                {/* <TableCell>{utente.company_name}</TableCell> */}
                <TableCell>{utente.first_name}</TableCell>
                <TableCell>{utente.last_name}</TableCell>
                <TableCell>
                  {utente.company_name ? utente.company_name : "-"}
                </TableCell>
                <TableCell>{utente.email}</TableCell>
                <TableCell>{utente.adminLevel}</TableCell>
                <TableCell>
                  {utente.enabled ? (
                    <CheckIcon color="success" />
                  ) : (
                    <CloseIcon color="error" />
                  )}
                </TableCell>
                <TableCell>
                  <div
                    style={{ display: "flex", gap: 25, justifyContent: "end" }}
                  >
                    <EmailIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        setPopupEmail(true);
                        setUtenteEmail(utente.email);
                      }}
                    />
                    {utente.adminLevelId == 2 ? (
                      <a
                        href={"/utente/files/" + utente.id}
                        title="Files utente"
                        style={{ color: "#111" }}
                      >
                        <FolderIcon />
                      </a>
                    ) : (
                      <FolderIcon style={{ opacity: 0.3, cursor: "auto" }} />
                    )}
                    <DeleteIcon
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();
                        setPopupElimina(true);
                        setUtenteDaEliminare(utente.id);
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <br />
      <Pagination
        count={nPagine}
        page={pagina}
        onChange={(_e, value) => {
          setPagina(value);
          query(value);
        }}
      />

      <PopupElimina
        open={popupElimina}
        fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => eliminaUtente(utenteDaEliminare)}
      />
      <PopupMail
        open={popupEmail}
        fnAnnulla={(e) => setPopupEmail(false)}
        fnSuccess={(e) => inviaEmail(utenteEmail)}
      />
    </>
  );
};

export default Comp;
